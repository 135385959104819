.table__header {
    display: flex;
    padding: 13px 0;
    gap: 20px;
    justify-content: space-between;
    .table__search {
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
        span {
            color: #697a8d;
            font-size: 15px;
            font-weight: 400;
            line-height: 23px;
        }
        input {
            border-radius: 6px;
            color: #697a8d;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            border: 1px solid #d9dee3;
            padding: 7px 20px;
            &::placeholder {
                color: #c5ccd3;
            }
        }
    }
    .table__export {
        color: #8592a3;
        border-color: rgba(0,0,0,0);
        background: #ebeef0;
        border-radius: 6px;
        padding: 7px 20px;
        display: flex;
        justify-content: center;
        gap: 5px;
        align-items: center;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        &:hover {
            background: #788393;
            color: #feffff;
        }
    }
    .table__select-rows {
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
        span {
            color: #697a8d;
            font-size: 15px;
            font-weight: 400;
            line-height: 23px;
        }
        select {
            border-radius: 6px;
            color: #b4bdc6;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            border: 1px solid #d9dee3;
            padding: 7px 20px;
        }
    }
}

table {
    border: 1px solid #ECEEF1;
    text-align: left;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    th, td {
        padding: 8px 15px;
    }
    th {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
    }
    thead {
        border-bottom: 1px solid #ECEEF1;
        th {
            position: relative;
            .sort__icon {
                color:#435971;
                opacity: 0.2;
            }
            .sort__icon:first-child {
                position: absolute;
                width: 10px;
                right: 20px;
                top: 5px;
            }
            .sort__icon:last-child {
                position: absolute;
                width: 10px;
                right: 20px;
                bottom: 5px;
            }
            &.sort-asc {
                .sort__icon:first-child {
                    opacity: 1;
                }
            }
            &.sort-desc {
                .sort__icon:last-child {
                    opacity: 1;
                }
            }
        }
    }
    tbody {
        td {
            font-size: 15px;
            font-weight: 400;
            color: #697A8D;
            padding: 15px;
        }
        tr {
            border-bottom: 1px solid #ECEEF1;
            
        }
        .first-column {
            font-weight: 800;
        }
    }
    .device-link {
        font-size: 15px;
        font-weight: 600;
        line-height: 23px;
        color: #697a8d;
        text-decoration: none;
    }
}