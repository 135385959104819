$max: 10;
$offset: 1;
$unit: 'rem'; // Feel free to change the unit.
@mixin list-loop($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} { 
      #{$styleName}: #{$i + $unit};
    }
    $i: $i + $offset;
  }
}
// Margins
@include list-loop('.mt-', 'margin-top');
@include list-loop('.m-r-', 'margin-right');
@include list-loop('.m-b-', 'margin-bottom');
@include list-loop('.m-l-', 'margin-left');
@include list-loop('.m-x-', 'margin');
// Paddings
@include list-loop('.p-t-', 'padding-top');
@include list-loop('.p-r-', 'padding-right');
@include list-loop('.p-b-', 'padding-bottom');
@include list-loop('.p-l-', 'padding-left');
@include list-loop('.p-x-', 'padding');