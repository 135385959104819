@import '../core/config.scss';
.content--boxes {
    padding: 1.5rem 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));    
    gap: 1.5rem;
    max-width: 940px;
    margin: auto;
    box-sizing: border-box;
    @media screen and (max-width:480px){
        display: flex;
        flex-wrap: wrap;;
    }
  }

.box {
    color: rgba(50, 71, 92, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(50 71 92 / 10%) 0px 2px 10px 0px;
    background-image: none;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    padding: 1.5rem;
    @include themed() {
      background-color: t('box-bg');
    }
    @media screen and (max-width:480px){
        width: 100%;
    }
}

.box--number {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .box__title {
    font-size: 1rem;
    line-height: 1.5;
    color: $gray-main-light;
    font-weight: 600;
    margin: 0;
  }
  .box__number {
    font-size: 1.5rem;
  }
  .box__icon {
    font-size: 30px;
    padding: 10px;
    background-color: $primary-light;
    color: $white;
    border-radius: 8px;
  }
  .page {
    h1 {
        color: #566A7F;
        font-size: 18px;
        font-weight: 400;
    }
  }
}

.minimal {
    .title {
        color: #697A8D;
        font-size: 12px;    
        font-weight: 400;
        line-height: 23px;
        display: block;
    }
    .stat {
        display: block;
        color: #566a7f;
        font-size: 26px;
        font-weight: 400;
        line-height: 36.4px;
        small {
            color: #697A8D;
            font-size: 12px;
        }
    }
    .stats {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 10px;
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        span {
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
        }
    }

  }

  .box-2 {
    grid-column: span 2;
  }


  .rentingDates {
    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .date {
        color: #b4bdc6;
        font-weight: 600;
        font-size: 13px;
    }
    progress {
        width: 100%;
        -webkit-appearance: none;
        appearance: none;
        margin-top: 10px;
        &::-webkit-progress-bar {
            border-radius: 10rem;
            height: 0.75rem;
            background-color: rgba(67,89,113,.1);
        }
        &::-webkit-progress-value {
            background-color: #696cff;
            border-radius: 10rem;
            box-shadow: 0 2px 4px 0 rgba(105,108,255,.4);
        }
    }
  }