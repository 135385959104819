@import '../core/config.scss';

.topbar {
    position: relative;
    z-index: 1;
    background: $white;
    border-radius: 8px;
    backdrop-filter: saturate(200%) blur(6px);
    padding: 0rem 1.5rem !important;
    min-height: 64px !important;
    display: flex;
    gap: 30px;
    align-items: center;
    backdrop-filter: saturate(200%) blur(6px);
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: rgb(50 71 92 / 10%) 0px 2px 10px 0px;
    padding: 0rem 1.5rem !important;
    margin: 0.75rem auto 0;
    max-width: 940px;
    box-sizing: border-box;
    justify-content: flex-end;
    .col--user {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        flex-grow: 1;
    }
    .topbar__menutoggle {
        display: none;
        @media screen and (max-width:768px){
            display: block;
        }
    }
    .topbar__user {
        background-color: $primary-light;
        border-radius: 50%;
        padding: 10px;
        color: $white;
        font-size: 20px;
        justify-self: flex-end;
    }
    .topbar__logout {
        justify-self: flex-end;
    }
    .topbar__switcher {
        color: $primary-light;
        font-size: 20px;
        padding: 10px;
        border-radius: 50%;
        transition: 200ms ease-in-out;
        cursor: pointer;
        &:hover {
            background: rgba($primary-light, 0.2)
        }
    }
    .topbar__icon {
        cursor: pointer;
        color: #697a8d;
        &:hover {
            color: #566a7f;
        }
    }
    @include themed() {
        background-color: t('box-bg');
    }
}