@import "../core/config.scss";

.ps-sidebar-container {
  overflow-y: visible !important;
  overflow-x: visible !important;
}

.sidebar {
  background-color: $white;
  box-shadow: rgb(50 71 92 / 10%) 0px 2px 10px 0px;
  border-right-width: 0 !important;
 @include themed() {
    background-color: t('box-bg');
  }
  .sidebar__closeIcon {
    border-radius: 50%;
    padding: 4px;
    font-size: 14px;
    position: absolute;
    top: 26px;
    right: -20px;
    cursor: pointer;
    transition: 200ms ease-in-out;
    background-color: $primary-light;
    @include themed() {
      border: 8px solid t('bg');
      color: $white;
    }
    &:hover {
      @include themed() {
        background: t('bg');
        color: $primary-light;
      }
    }
  }

  .sidebar__logoWrapper {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sidebar__logo {
    overflow: hidden;
    max-width: 80%;
    height: 100%;
    @include themed() {
      color: t('text');
     }
  }

  .sidebar__logo--short {
    height: 40px;
  }

  .sidebar__item {
    .ps-menu-button {
      padding: 0 5px;
      margin: 0 1rem;
      border-radius: 6px;
      color: $gray-main-light;
      transition: 0.25s ease-in-out 0s;
      cursor: pointer;
      &:hover {
        background-color: rgba($color: $primary-light, $alpha: 0.04);
      }
      @include themed() {
        color: rgba(t('text'), 0.7);
       }
      &.ps-active {
        background: rgba($primary-light, 0.2);
        @include themed() {
          color: t('text');
         }
      }
    }
    .ps-menu-label {
        font-size: 15px;
    }
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  .css-dip3t8 {
    background-color: transparent;
  }
  .css-1wvake5 {
    border: none;
  }
}
