.badge {
    display: inline-block;
    padding: 0.52em 0.593em;
    font-size: 0.8125em;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    text-transform: uppercase;
    line-height: .75;
    &-success {
        background-color: #8cda55;
    }
    &-danger {
        background: #eb5032;
    }
    &-warning {
        background: #f3af3d;
    }
}