.login {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #f5f5f9;
    align-items: center;
    justify-content: center;
    .login__content {
        position: relative;
        .login__topIllustration {
            position: absolute;
            width: 148px;
            height: 148px;
            right: -50px;
            top: -50px;
            z-index: 0;
        }
        .login__bottomIllustration {
            position: absolute;
            width: 148px;
            height: 148px;
            left: -50px;
            bottom: -50px;
            z-index: 0;
        }
    }
    .login__box {
        max-width: 350px;
        width: 100%;
        border-radius: 6px;
        background: #ffffff;
        background-blend-mode: normal;
        box-shadow: 0px 2px 6px rgba($color: #435971, $alpha: 0.12);
        padding: 40px 30px;
        position: relative;
        z-index: 2;
        box-sizing: border-box;
        @media screen and (max-width:480px){
            padding: 1rem;
        }
        .logo {
            display: block;
            max-width: 200px;
            width: 100%;
            margin: auto;
        }
        h3 {
            margin-top: 1rem;
            color: #697a8d;
            font-size: 15px;
            font-weight: 400;
            line-height: 23px;
        }
    }
    .form {
        padding-top: 2rem;
        label {
            display: block;
            color: #566a7f;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            padding-bottom: 5px;
        }
        input {
            display: block;
            width: 100%;
            border-radius: 6px;
            background: #ffffff;
            border: 1px solid #d9dee3;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            padding: 0.5rem 1rem;
            box-sizing: border-box;
            margin-bottom: 1rem;
            &::placeholder {
                color: #b4bdc6;
            }
        }
        .button {
            display: block;
            width: 100%;
            border-radius: 6px;
            margin-top: 2rem;
            background: #696cff;
            padding: 0.5rem 1rem;
            color: #ffffff;
            font-size: 15px;
            font-weight: 400;
            text-align: center;
            letter-spacing: 0.42857143px;
            outline: none;
            border: none; 
            border: 1px solid #696cff;  
            cursor: pointer;
            transition: 300ms ease-in-out;
            &:hover {
                background: transparent;
                color: #696cff;
            }
        }
    }
    .login__languageSwitcher {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        .languageSwitcher {
            cursor: pointer;
            .languageSwitcher__dropdown {
                bottom: 20px;
            }
        }
    }
}