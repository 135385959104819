$primary-light: #696CFF;
$secondary-light: #8592A3;
$success-light: #71DD37;
$danger-light: #FF3E1D;
$warning-light: #FFAB00;
$info-light: #03C3EC;
$gray-main-light: #435971;
$gray-bg-light: #F5F5F9;
$dark: #233446;
$light: #FCFDFD;


$white: #fff;


$themes: (
        light: (
                bg: $gray-bg-light,
                text: $gray-main-light,
                box-bg: $white,
        ),
        dark: (
                bg: #232333,
                text: $light,
                box-bg: #2b2c40,
        ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}