@import '../core/config.scss';
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500,600,700;800&display=swap');

* {
  padding: 0;
  margin: 0;
}

.app {
  display: flex;
  min-height: 100vh;
  font-family: 'Public sans', sans-serif;
  width: 100%;
    display: flex;
    flex: 1 1 auto;
    align-items: stretch;
  @include themed() {
    background: t('bg');
  }
  @media screen and (max-width:480px){
    height: auto;
    min-height: 100vh;
  }
}
.content {
  padding: 0 20px;
  width: 100%;
  @media screen and (max-width:480px){
    overflow: hidden;
  }
}

.white-content {
  background: #e4e4e4;
}

