.breadCrumbs {
    list-style: none;
    display: flex;
    gap:10px;
    padding: 1rem 0 0;
    max-width: 940px;
    margin: auto;
    flex-wrap: wrap;
    li, a {
        font-size: 22px;
        font-weight: 400;
        line-height: 30.8px;
        color: #a1acb8;
        text-decoration: none;
        &:last-child {
            color: #566A7F;
        }
        &:not(:first-child):before {
            content: "/ "
        }
    }
}