@import "../core/config.scss";

.languageSwitcher {
    position: relative;
    display: inline-block;
    height: 16px;
    .languageSwitcher__dropdown {
        display: none;
        position: absolute;
        background-color: $white;
        min-width: 160px;
        box-shadow: 0 0.25rem 1rem rgba(161,172,184,.45);
        z-index: 2;
        right: 0;
        a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            cursor: pointer;
            transition: 0.2s all ease-in-out;
            &.active {
                background-color: rgba(105,108,255,.08);
                color: #696cff !important;
            }
            &:hover {
                background-color: #f8f8f9;
            }
        }
        &.show {
            display: block;
        }
    }
}
